<template>
  <div class="row mx-0">
    <div class="col-12 py-3 px-0" :class="{'lightgrey_bg': isOdd}">
      <!--DESKTOP-->
      <div class="row m-0 align-items-center d-none d-md-flex">
        <div class="col-md-2 text-center font15">
          <div class="row m-0 align-items-center">
            <div class="col-auto p-0">
              {{item.debitOrderID}}
            </div>
          </div>
        </div>
        <div class="col-md-2 text-center font15 text-capitalize">
          {{frequency}}
        </div>
        <div class="col-md-2 text-center font15 text-capitalize">
          {{method}}
        </div>
        <div class="col-md text-center font15 bold">
          {{date}}
        </div>
        <div class="col-md-2 text-center bold font15">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-md-2 text-center">
          <div class="row align-items-center justify-content-end">
            <div class="col-auto px-1">
              <Button color="green" btnText="VIEW" size="small" @click="view" icon="isLoading" :isLoading="isLoading"></Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey" icon="arrow" size="xxsmall" @click="print">
                <IconFile size="size12" />
              </Button>
            </div>
            <div class="col-auto px-1">
              <Button color="darkgrey_red" icon="arrow" size="xxsmall" @click="deleteOrder">
                <ios-close-icon class="inline-icon"/>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row mx-0 align-items-center d-md-none position-relative font13">
        <div class="col-3 p-0 text-center">
          {{item.debitOrderID}}
        </div>
        <div class="col-3 p-0 text-center">
          {{date}}
        </div>
        <div class="col-3 p-0 text-center bold">
          {{ defaultCurrency }} {{ parseFloat(Math.round(item.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
        </div>
        <div class="col-3 p-0 text-center">
          <Button color="green" btnText="VIEW" size="small" @click="view" icon="isLoading" :isLoading="isLoading"></Button>
        </div>
      </div>
      <!--END MOBILE-->
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    IconFile: defineAsyncComponent(() => import('../components/icons/IconFile.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'RecurringDonationItem',
  props: ['item', 'thekey', 'isReady'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      isSelected: false,
      isLoading: false
    }
  },
  watch: {
    isReady () {
      if (this.isReady) {
        this.isLoading = false
      }
    }
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.startDate))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    frequency () {
      const lowercase = this.item.frequency.toLowerCase()
      return lowercase[0].toUpperCase() + lowercase.slice(1)
    },
    method () {
      if (this.item.donorBankAccount.bankName) {
        const lowercase = this.item.donorBankAccount.bankName.toLowerCase()
        return lowercase[0].toUpperCase() + lowercase.slice(1)
      } else {
        return 0
      }
    },
    isOdd () {
      return this.thekey % 2
    }
  },
  methods: {
    ...mapActions(['downloadDebitOrderDetails']),
    view () {
      this.isLoading = true
      this.$emit('showDetails')
    },
    deleteOrder () {
      this.$emit('deleteOrder')
    },
    async print () {
      await this.downloadDebitOrderDetails(this.item.debitOrderID)
    }
  }
}
</script>

<style scoped>
.dark_text {
  color: var(--normal-color-dark);
}
.position_absolute {
  position: absolute;
  left: -2rem;
}
</style>
